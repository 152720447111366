import { Box, Button, Grommet } from "grommet";
import { Game } from "./Game";

export function App() {
  return (
    <Grommet full>
      <Box align="center" pad="medium">
        <Game />
      </Box>
    </Grommet>
  );
}
