import {
  Anchor,
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Text,
  TextInput,
  ThemeContext,
} from "grommet";
import { Alert, Github } from "grommet-icons";
import React, { useState } from "react";

interface SeriesData {
  label: string;
  placeholder: string;
  result: string;
}

const series = [
  {
    label: "hi there",
    placeholder: "hi there",
    result: "hi there",
  },
  {
    label: "hope you're well",
    placeholder: "thanks",
    result: "thanks",
  },
  {
    label: "bleep",
    placeholder: "",
    result: "bloop",
  },
  {
    label: "bloop",
    placeholder: "",
    result: "bleep",
  },
  {
    label: "bleop",
    placeholder: "",
    result: "bloep",
  },
  {
    label: "eieio",
    placeholder: "",
    result: "oioie",
  },
  {
    label: "se it's liko a werd gamo",
    placeholder: "",
    result: "so it's like a word game",
  },
];

const failColors = ["#FFD275", "#EB3F33", "#BA1E12"];

export function Game() {
  const [failCount, setFailCount] = useState(0);
  const [answer, setAnswer] = useState("");
  const [seriesIndex, setSeriesIndex] = useState(0);

  const checkAnswer = () => {
    if (series[seriesIndex].result == answer) {
      setSeriesIndex(seriesIndex + 1);
      setAnswer("");
      setFailCount(0);
    } else if (failCount >= 3) {
      console.log("u fail");
      document.location.reload();
    } else {
      setFailCount(failCount + 1);
    }
  };

  return (
    <Box width="medium" gap="medium">
      <Heading>Bleep Bloop</Heading>
      <Text>{series[seriesIndex].label}</Text>
      <TextInput
        icon={
          failCount > 0 ? <Alert color={failColors[failCount - 1]} /> : <></>
        }
        placeholder={series[seriesIndex].placeholder}
        name="answer"
        onChange={(event) => setAnswer(event.target.value)}
        onKeyPress={(evt) => {
          if (evt.key == "Enter") {
            checkAnswer();
          }
        }}
        value={answer}
      />
      <Anchor
        alignSelf="center"
        icon={<Github size="large" color="dark-5" />}
        href="https://github.com/bengreenier/bleep-bloop"
        target="_blank"
      />
    </Box>
  );
}
